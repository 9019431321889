import logo from "./assets/img/logo.png";
import title from "./assets/img/text.svg";
import telegram from "./assets/img/telegram.svg";
import twitter from "./assets/img/twitter.svg";
import db from "./assets/img/db.svg";
import chart from "./assets/img/chart.svg";

import "./App.css";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import { useState } from "react";

function App() {
  const [showBorder, setShowBorder] = useState(false);
  return (
    <div className="App bg-[url('./assets/img/bg.png')] bg-no-repeat bg-cover min-h-[100vh] flex justify-center items-center relative">
      <div className="px-5 py-10 w-full">
        <div className="max-w-3xl mx-auto">
          <ul className="flex gap-4 justify-center font-medium text-sm md:gap-5">
            <li>
              <a
                href="https://twitter.com/TosakenTokenETH"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-[#BA302C] py-2 px-6 border-[1px] border-black-custom rounded-xl text-white flex justify-between items-center gap-2 transition-all hover:bg-white hover:text-black"
              >
                <div className="bg-white w-[30px] h-[30px] flex items-center justify-center rounded-full">
                  <img
                    className="w-[22px] h-[22px]"
                    src={twitter}
                    alt="twitter"
                  />
                </div>
                Twitter
              </a>
            </li>
            <li>
              <a
                href="https://t.me/TosakenTokenETH"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-[#BA302C] py-2 px-6 border-[1px] border-black-custom rounded-xl text-white flex justify-between items-center gap-2 transition-all hover:bg-white hover:text-black"
              >
                <div className="bg-white w-[30px] h-[30px] flex items-center justify-center rounded-full">
                  <img
                    className="w-[20px] mr-[2px] mt-[2px] h-[20px] inline-flex"
                    src={telegram}
                    alt="telegram"
                  />
                </div>
                Telegram
              </a>
            </li>
          </ul>

          <img
            className="max-w-xs mx-auto pt-[70px] lg:pt-[110px]"
            src={logo}
            alt=""
          />
          <div className="text-center border-2 bg-white-custom border-black-custom mt-12 mb-7 py-5 px-14 rounded-xl">
            <div className="max-w-[400px] mx-auto py-5">
              <img className="w-full" src={title} alt="title" />
            </div>
            {/* <p className="mt-3 text-xl w-full">
              Official CA: <span className="font-bold"></span>
            </p> */}
            <p className="text-xl">
              <span className="text-[#BA302C]">Supply:</span> 6, 900, 000, 000
            </p>
            <p className="text-xl">
              <span className="text-[#BA302C]">Tax:</span> 0/0
            </p>
          </div>
          <div
            className={`w-full ${
              showBorder ? "border-black-custom" : "border-transparent"
            } border-2 rounded-xl overflow-hidden`}
          >
            <TwitterTimelineEmbed
              sourceType="TosakenTokenETH"
              screenName="TosakenTokenETH"
              options={{ height: 500 }}
              noBorders
              onLoad={() => {
                setShowBorder(true);
              }}
            />
          </div>
          <ul className="pt-7 flex justify-center gap-4 md:gap-8">
            <li>
              <a className="bg-black-custom py-3 px-5 rounded-xl text-white transition-all flex items-center justify-center gap-3  hover:bg-[#BA302C]">
                <img
                  className="w-[15px] h-[15px] inline-flex"
                  src={db}
                  alt="db"
                />
                Contract
              </a>
            </li>
            <li>
              <a
                href="#"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-black-custom py-3 px-5 rounded-xl text-white transition-all flex items-center justify-center gap-3 hover:bg-[#BA302C]"
              >
                <img
                  className="w-[15px] h-[15px] inline-flex"
                  src={chart}
                  alt="chart"
                />
                Chart
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default App;
